import {
  createStylesParam as untypedCreateStylesParam,
  StyleParamType,
} from '@wix/tpa-settings';
import { useStyles } from '@wix/tpa-settings/react';

import {
  RssWidgetIconType,
  type RssWidgetStyleParams,
} from '@wix/communities-blog-client-common';

export function useRssWidgetStyleParams(): RssWidgetStyleParams {
  const styles = useStyles();

  const { colors } = styleParams;
  const enums = getEnumsFromNumbers();

  return {
    colors: {
      'widget-iconColor': styles.get(colors['widget-iconColor']),
      'widget-iconBackgroundColor': styles.get(
        colors['widget-iconBackgroundColor'],
      ),
      'widget-iconColorNoBackground': styles.get(
        colors['widget-iconColorNoBackground'],
      ),
    },
    numbers: {
      'widget-iconBackgroundType': enums.backgroundType,
    },
  };

  function getEnumsFromNumbers() {
    const { numbers } = styleParams;
    const backgroundType: RssWidgetIconType =
      styles.get(numbers['widget-iconBackgroundType']) ??
      RssWidgetIconType.Square;

    return { backgroundType };
  }
}

const styleParams = {
  colors: {
    'widget-iconColor': makeColor('widget-iconColor'),
    'widget-iconBackgroundColor': makeColor('widget-iconBackgroundColor'),
    'widget-iconColorNoBackground': makeColor('widget-iconColorNoBackground'),
  },
  numbers: {
    'widget-iconBackgroundType': makeNumber(
      'widget-iconBackgroundType',
      RssWidgetIconType.Square,
    ),
  },
} satisfies {
  colors: Record<keyof RssWidgetStyleParams['colors'], any>;
  numbers: Record<keyof RssWidgetStyleParams['numbers'], any>;
};

function makeColor(key: keyof RssWidgetStyleParams['colors']) {
  return untypedCreateStylesParam(key, {
    type: StyleParamType.Color,
  });
}

function makeNumber<K extends keyof RssWidgetStyleParams['numbers']>(
  key: K,
  fallbackValue: RssWidgetStyleParams['numbers'][K],
) {
  return untypedCreateStylesParam(key, {
    type: StyleParamType.Number,
    getDefaultValue: () => fallbackValue,
  });
}

export default {
  ...styleParams.colors,
  ...styleParams.numbers,
};
